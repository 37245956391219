:root {
    --background-color: #fff;
    --content-color: #15171a;
}

@media (prefers-color-scheme: dark) {
    :root {
        --background-color: #222;
        --content-color: #dfdfdf;
    }
}


html, body, #root {
    width: 100%;
    height: 100%;
    background-color: var(--background-color);
    color: var(--content-color);
}



.App {
    min-height: 100%;
    position: relative;
}

.loading {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    padding: 0 1em;
    box-sizing: border-box;
    word-wrap: break-word;
    line-height: 1.4;
}
